.offerings-section {
  padding: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
}

.section-title {
  width: 100%;
  text-align: center;
  margin-bottom: 40px;
  font-size: 42px;
  color: #333;
}

.offering {
  flex-basis: calc(20% - 20px);
  margin-bottom: 10px;
  padding: 20px;
  text-align: center;
  background: #fff;
  border-radius: 8px;
  transition: transform 0.3s;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.offering:hover {
  transform: translateY(-5px);
}

.offering .icon {
  width: 50px;
  height: 50px;
  margin: 0 auto 10px;
  background-size: cover;
}

.offering h3 {
  margin-bottom: 10px;
  font-size: 20px;
  color: #333;
}

.offering p {
  font-size: 16px;
  color: #666;
}

@media (max-width: 992px) {
  .offering {
    flex-basis: calc(20% - 20px);
  }
}

@media (max-width: 768px) {
  .offering {
    flex-basis: calc(100% - 20px); /* Bereken flex-basis voor 1 kaart per rij */
  }
}

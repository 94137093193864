.portfolio {
  padding: 20px;
  margin-bottom: 100px;
}

.portfolio-header {
  text-align: center;
  margin-bottom: 40px;
  font-size: 42px;
  color: #333;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 20px;
}

.project-card {
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff;
  overflow: hidden;
}

.project-card img {
  width: 100%;
  height: auto;
  max-height: 200px;
  object-fit: cover;
  border-radius: 8px 8px 0 0;
}

.project-details {
  padding: 20px;
}

.technologies {
  display: flex;
  flex-wrap: wrap;
}

.technology-label {
  background-color: #5c2d91;
  color: #fff;
  padding: 5px 10px;
  border-radius: 20px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.partners-section {
  text-align: center;
  padding: 50px;
}

.partners-section h2 {
  width: 100%;
  text-align: center;
  margin-bottom: 40px;
  font-size: 42px;
  color: #333;
}

.pyramid {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.row {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.logo {
  max-height: 100px;
  margin: 0 10px;
  opacity: 0.8;
  transition: opacity 0.3s ease;
}

.logo:hover {
  opacity: 1;
}

@media (max-width: 768px) {
  .logo {
    max-height: 150px;
  }
}

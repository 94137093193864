.footerContainer {
  background-color: #5c2d91;
  color: white;
  display: flex;
  justify-content: space-around;
  padding: 20px 10px;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  flex-wrap: wrap;
  position: relative;
  min-height: 225px;
}

.txtLeft,
.txtRight {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.titleFooterLeft,
.titleFooterRight {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 10px;
}

.txtLeftFooter,
.txtLeftFooter a {
  margin: 5px 0;
  font-size: 0.9em;
  color: white;
  text-decoration: none;
}

.socialMedia {
  display: flex;
  align-items: center;
  gap: 15px;
}

.socialIcon {
  color: white;
  font-size: 2em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.copyright {
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 0.8em;
  color: rgba(255, 255, 255, 0.7);
}

@media (max-width: 768px) {
  .footerContainer {
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
  }

  .txtLeft,
  .txtRight {
    align-items: center;
    margin-bottom: 50px;
  }

  .socialIcon {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

html,
body,
.root {
  height: 100%;
  margin: 0;
  font-family: Montserrat, sans-serif;
}

.bodyWrapper {
  background-image: url(./images/background.jpg);
  height: 100%;
  width: 100%;
  overflow: auto;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
}

.title {
  text-align: center;
  color: white;
  font-size: 10rem;
}

.footer {
  padding-inline: 30%;
  color: white;
  font-size: 1.5rem;
  white-space: pre-line;
}

.contact-section {
  padding: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.contact-container {
  width: 100%;
  max-width: 800px;
}

.contact-container h2 {
  width: 100%;
  text-align: center;
  margin-bottom: 40px;
  font-size: 42px;
  color: #333;
}

.contact-container p {
  font-size: 1.2em;
  color: #555;
  margin-bottom: 30px;
}

.contact-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.contact-form {
  width: 100%;
  max-width: 400px;
  margin-bottom: 20px;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.contact-form button {
  width: 100%;
  background-color: #5c2d91;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-info {
  width: 100%;
  max-width: 400px;
  text-align: left;
}

.contact-info p {
  font-size: 1.1em;
  color: #444;
}

@media (max-width: 768px) {
  .contact-content {
    flex-direction: column;
    align-items: center;
  }

  .contact-info {
    text-align: center;
  }
}

.tech-section {
  padding: 50px;
  margin: 0 auto;
  background-color: #f9fbfe;
}

.section-title {
  text-align: center;
  margin-bottom: 40px;
  font-size: 42px;
  color: #333;
}

.tech-info {
  text-align: center;
  margin-bottom: 20px;
  font-size: 18px;
  color: #666;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 200px;
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card img {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
  transition: transform 0.2s; /* Animation */
}

.card:hover img {
  transform: scale(
    1.2
  ); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.card strong {
  text-align: center;
}

.card-container {
  display: flex;
  margin-bottom: 40px;
  flex-wrap: wrap;
  justify-content: center;
}

@media (max-width: 768px) {
  .tech-section {
    padding: 20px;
  }

  .section-title {
    font-size: 28px;
    margin-bottom: 20px;
  }

  .tech-info {
    font-size: 16px;
  }

  .card {
    width: 120px;
    height: 150px;
  }

  .card img {
    width: 60px;
    height: 60px;
  }

  .card-container {
    margin-bottom: 20px;
  }
}

.pricing-section {
  padding: 50px;
  margin: 0 auto;
  background-color: #f9fbfe;
}

.section-title {
  text-align: center;
  margin-bottom: 40px;
  font-size: 42px;
  color: #333;
}

.pricing-info {
  text-align: center;
}

.pricing-info p {
  margin-bottom: 20px;
  font-size: 18px;
  color: #666;
}

.contact-form {
  max-width: 500px;
  margin: 0 auto 40px;
}

.form-group {
  margin-bottom: 20px;
}

input[type="text"],
input[type="email"],
input[type="name"],
input[type="phone"],
input[type="company"],
textarea,
.submit-button {
  width: calc(100% - 22px);
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  box-sizing: border-box;
}

textarea {
  height: 100px;
}

@media (max-width: 576px) {
  .pricing-section {
    padding: 30px;
  }

  .section-title {
    margin-bottom: 30px;
    font-size: 32px;
  }

  .pricing-info p {
    margin-bottom: 15px;
    font-size: 14px;
  }

  .contact-form {
    margin-top: 30px;
  }

  textarea {
    height: 80px;
  }
}

textarea {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  border: inherit;
  padding: inherit;
  margin: inherit;
}

textarea::placeholder {
  font-family: inherit;
  font-size: inherit;
}

/* Navbar.css */
.navbar {
  background-color: #5c2d91;
  padding: 10px 20px;
}

.navbar-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  color: white;
  font-weight: bold;
  font-size: 30px;
}

.nav-items {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-items a {
  color: white;
  text-decoration: none;
  margin: 0px 20px;
  padding: 5px 0;
  font-size: 16px;
  font-weight: bold;
  position: relative;
}

.nav-items a:not(.nav-language):after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: white;
  transition: width 0.3s;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.nav-items a:not(.nav-language):hover:after {
  width: 100%;
}

.menu-icon {
  display: none;
  cursor: pointer;
}

.bar {
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 3px 0;
  transition: transform 0.3s, opacity 0.3s;
}

@media (max-width: 768px) {
  .menu-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    position: relative;
  }

  .bar {
    width: 25px;
    height: 3px;
    background-color: white;
    margin: 3px 0;
    transition: transform 0.3s, opacity 0.3s;
  }

  .nav-items {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50px;
    right: 20px;
    background-color: #5c2d91;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 0.3s, transform 0.3s ease-in-out;
    pointer-events: none;
  }

  .nav-items.active {
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
  }
}

/* HomePage.css */
.home {
  font-family: "Arial", sans-serif;
  background-color: #5c2d91;
  color: white;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hero-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px;
  width: 80%;
  margin: 0 auto;
}

.hero-text {
  max-width: 600px;
  text-align: center;
  margin: 0 auto;
}

.hero-text h1 {
  font-size: 48px;
  margin-bottom: 20px;
}

.hero-text p {
  font-size: 24px;
  line-height: 1.4;
}

.contact-button {
  background-color: #ffc107;
  color: #5c2d91;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

.contact-button:hover {
  background-color: #e0a800;
}

.hero-image img {
  width: auto;
  height: 400px;
}

@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}

.hero-image img {
  max-width: 100%;
  height: auto;
  animation: float 3s ease-in-out infinite; /* Pas de animatienaam, duur, timing functie en herhaling toe */
}

.contact-us-button,
.learn-more-button {
  background-color: #29b6f6;
  color: white;
  border: none;
  padding: 15px 30px;
  border-radius: 8px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-us-button:hover,
.learn-more-button:hover {
  background-color: #0077c2; /* Darker shade on hover */
}

@media only screen and (max-width: 768px) {
  .hero-section {
    flex-direction: column;
    padding: 30px;
  }
  .hero-text {
    max-width: 100%;
  }
  .hero-text h1 {
    font-size: 36px;
  }
  .hero-text p {
    font-size: 20px;
  }
  .contact-button {
    font-size: 16px;
    margin-top: 10px;
  }
  .hero-image img {
    height: auto;
  }
}
